import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import {
  ShareOutlined,
  TimerUniversal,
  ServerOutlined,
  HomeOutlined,
  SettingsOutlined,
  SearchUniversal,
  HawkerCenterOutlined,
  GridViewOutlined,
} from '@duxton-web/icons';
import { isAuthenticated } from 'grab-login/lib/helper';
import Bundle from './Bundle';
import Spinner from '../spinner/Spinner';
import LazyWithErrorBoundary from './LazyWithErrorBoundary';
import Component from '../Home';

const JobsItemList = lazy(() => import('../puxian_jobs/ItemList'));
const Home = lazy(() => import('../Home'));
const StreamsItemList = lazy(() => import('../puxian_streams/ItemList'));
const StreamsDetails = lazy(() => import('../puxian_streams/Details'));
const ScriptsItemList = lazy(() => import('../puxian_scripts/ItemList'));
const JobsDetails = lazy(() => import('../puxian_jobs/Details'));
const ScriptsDetails = lazy(() => import('../puxian_scripts/Details'));
const NotFound = lazy(() => import('../NotFound'));
const SearchConfigurations = lazy(() => import('@pages/searchConfig'));
const ESClusters = lazy(() => import('@pages/esClusters'));
const ESClusterDetail = lazy(() => import('@pages/esClusterDetail/Index'));
const SingleNamespaceConfiguration = lazy(() =>
  import('@pages/singleNamespace')
);

export const renderAsync = (asyncLoad, _props, authRequired = true) => {
  const bundleComp = (
    <Bundle load={asyncLoad}>
      {(Component) =>
        Component ? <Component {..._props} /> : <Spinner size="large" />
      }
    </Bundle>
  );

  if (authRequired) {
    if (!isAuthenticated()) {
      const to = { pathname: '/login' };

      if (_props && _props.location) {
        to.state = { from: _props.location };
      }

      return <Navigate to={to} />;
    }
  }

  return bundleComp;
};

export const ROUTES = [
  {
    path: '/',
    index: true,
    key: '/',
    label: 'Home',
    icon: <HomeOutlined />,
    element: LazyWithErrorBoundary(<Home />),
  },
  {
    path: 'jobs',
    index: true,
    key: '/jobs',
    label: 'Schedule Jobs',
    icon: <TimerUniversal />,
    element: LazyWithErrorBoundary(<JobsItemList />),
  },
  {
    path: 'jobs/:id',
    hide: true,
    key: '/jobs/:id',
    element: LazyWithErrorBoundary(<JobsDetails />),
  },
  {
    path: 'streams',
    exact: true,
    element: LazyWithErrorBoundary(<StreamsItemList />),
    label: 'Streams',
    key: '/streams',
    icon: <ShareOutlined />,
  },
  {
    path: 'streams/:id',
    hide: true,
    element: LazyWithErrorBoundary(<StreamsDetails />),
    key: '/streams/:id',
  },
  {
    path: 'scripts',
    exact: true,
    label: 'Scripts',
    key: '/scripts',
    icon: <ServerOutlined />,
    element: LazyWithErrorBoundary(<ScriptsItemList />),
  },
  {
    path: 'scripts/:id',
    hide: true,
    key: '/scripts/:id',
    element: LazyWithErrorBoundary(<ScriptsDetails />),
  },
  {
    path: 'search',
    exact: true,
    element: LazyWithErrorBoundary(<SearchConfigurations />),
    label: 'Search',
    key: '/search',
    icon: <SettingsOutlined />,
  },
  {
    path: 'search-detail',
    // exac t: true,
    element: LazyWithErrorBoundary(<SingleNamespaceConfiguration />),
    // label: 'Search Configuration',
    hide: true,
    key: '/search-detail',
  },
  {
    path: 'es',
    exact: true,
    // element: LazyWithErrorBoundary(<SearchConfigurations />),
    label: 'ES Clusters',
    key: '/es',
    icon: <GridViewOutlined />,
    children: [
      {
        path: 'list',
        label: 'ES List',
        key: '/es/list',
        icon: <HawkerCenterOutlined />,
        exact: true,
        element: LazyWithErrorBoundary(<ESClusters />),
      },
      {
        path: 'list/:id',
        hide: true,
        key: '/es/list/:id',
        element: LazyWithErrorBoundary(<ESClusterDetail />),
      },
      // {
      //   path: 'search',
      //   label: 'ES Console',
      //   key: '/es/search',
      //   icon: <SearchUniversal />,
      //   exact: true,
      //   element: LazyWithErrorBoundary(<ESClusters />),
      // },
    ],
  },
  // {
  //   path: 'login',
  //   hide: true,
  //   element: LazyWithErrorBoundary(<Login />),
  // },
  {
    path: '*',
    key: 'notFound',
    hide: true,
    element: LazyWithErrorBoundary(<NotFound />),
  },
];
